<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="https://printeepro.com" class="mb-12">
        <img
          alt="Logo"
          src="media/logos/printeepro_logo_dark.png"
          class="h-45px"
        />
      </a>
      <!--end::Logo-->

      <div class="position-absolute top-0 end-0 mt-11 me-11">
        <strong class="fs-5 me-2">{{ $gettext("Language") }}:</strong>
        <el-select
          class="w-275px"
          v-model="curLang"
          :placeholder="$gettext('Language')"
        >
          <el-option
            v-for="item in countries"
            :key="item.name"
            :label="item.name"
            :value="item.value"
            :selected="item.selected"
          >
            <a @click="setLang(item.value)">
              <div class="w-230px">
                <span class="symbol symbol-20px me-4">
                  <img class="rounded-1" :src="item.flag" :alt="item.name" />
                </span>
                {{ item.name }}
              </div>
            </a>
          </el-option>
        </el-select>
      </div>

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100"
          id="kt_login_signin_form"
          @submit="onSubmitLogin"
          :validation-schema="login"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">
              {{ $gettext("Sign In to PrinteePRO") }}
            </h1>
            <!--end::Title-->
          </div>
          <!--begin::Heading-->

          <!--
          <div class="mb-10 bg-light-info p-8 rounded">
            <div class="text-info">
              {{
                $gettext(
                  "If you want to make a free PrinteePRO account please register"
                )
              }}
              <a href="https://register.printeepro.com"
                ><strong>{{ $gettext("HERE") }}</strong></a
              >. {{ $gettext("For more information, contact us on") }}
              <strong>support@printeepro.com</strong>
              {{ $gettext("or mobile") }}
              <strong>+386 69 777 525</strong>
            </div>
          </div>
          -->

          <!--begin::Label-->
          <div>
            <label class="form-label fs-6 fw-medium text-dark">{{
              $gettext(
                "We just emailed you a magic code for a password-free sign in."
              )
            }}</label>
          </div>

          <div>
            <label class="form-label fs-6 fw-medium text-dark">
              <a href="/#/sign-in">{{
                $gettext("Didn't receive the email?  Get another link")
              }}</a>
            </label>
          </div>
          <!--end::Label-->

          <!--begin::Label-->
          <div>
            <label class="form-label fs-6 fw-medium text-dark">{{
              $gettext(
                "Click on the link in the email or enter the code to access your dashboard."
              )
            }}</label>
          </div>
          <!--end::Label-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Label-->
            <label class="form-label fs-6 fw-bolder text-dark">{{
              $gettext("Token")
            }}</label>
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              name="token"
              :placeholder="$gettext('XXX-XXX')"
              autocomplete="on"
              autofocus
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="token" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              ref="submitButton"
              id="kt_check_submit"
              class="btn btn-lg btn-primary w-100 mb-5"
            >
              <span class="indicator-label">
                {{ $gettext("Launch Dashboard") }}
              </span>
              <span class="indicator-progress">
                {{ $gettext("Please wait...") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Submit button-->

            <!--begin::Separator-->
            <!--
            <div class="text-center text-muted text-uppercase fw-bolder mb-5">
              or
            </div>
            //end::Separator

            //begin::Google link
            <a
              href="#"
              class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
            >
              <img
                alt="Logo"
                src="media/svg/brand-logos/google-icon.svg"
                class="h-20px me-3"
              />
              Continue with Google
            </a>
            //end::Google link

            //begin::Google link
            <a
              href="#"
              class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
            >
              <img
                alt="Logo"
                src="media/svg/brand-logos/facebook-4.svg"
                class="h-20px me-3"
              />
              Continue with Facebook
            </a>
            //end::Google link

            //begin::Google link
            <a href="#" class="btn btn-flex flex-center btn-light btn-lg w-100">
              <img
                alt="Logo"
                src="media/svg/brand-logos/apple-black.svg"
                class="h-20px me-3"
              />
              Continue with Apple
            </a>
            -->
            <!--end::Google link-->
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a
          href="https://printeepro.com"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("About") }}</a
        >

        <a
          href="mailto:support@printeepro.com"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("Contact Us") }}</a
        >

        <a
          href="https://printeepro.com/privacy"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("Privacy Policy") }}</a
        >
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "check",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const gettext = useGettext();

    const curLang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";
    gettext.current = curLang;
    return {
      curLang,
    };
  },
  setup() {
    const router = useRouter();
    const gettext = useGettext();
    const store = useStore();

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      token: Yup.string().required().label(gettext.$gettext("Token")),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        submitButton.value.setAttribute("disabled", "true");
      }
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.CHECK, values)
          .then((val) => {
            console.log(val);
            router.push({ name: val });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: gettext.$gettext("Try again!"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.value?.removeAttribute("disabled");
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 100);
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      onSubmitLogin,
      login,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
</script>
